import { Button, Center, Container, Heading, Text } from "@chakra-ui/react";
import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import Card from "src/atoms/Card";
import Layout from "src/components/Layout";

const NotFoundPage = ({ data }: PageProps<Gatsby.NotFoundPageQuery>) => {
  return (
    <Layout>
      <Helmet title="404" />

      <Container as="section" maxW="container.lg">
        <Card size="lg">
          <Center>
            <Center
              bgColor="gray.800"
              bgImage={data.datoCmsGlobal?.background?.url}
              bgPosition="center"
              bgSize="cover"
              borderRadius="full"
              boxSize={{ base: "200px", sm: "300px", md: "400px" }}
              flexDirection="column"
              p={8}
            >
              <Heading
                as="h1"
                color="white"
                mb={4}
                size="lg"
                textAlign="center"
              >
                404
              </Heading>

              <Text color="white" fontSize="lg" fontWeight="semibold" mb={8}>
                Cette page est introuvable.
              </Text>

              <Button as={Link} to="/">
                Retour au site
              </Button>
            </Center>
          </Center>
        </Card>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundPage {
    datoCmsGlobal {
      background {
        url
      }
    }
  }
`;
